import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app.routing.module';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { LocalStorageModule } from 'angular-2-local-storage';

import { SharedComponentsModule } from './components/shared/shared.module';
import { ToasterService } from 'angular2-toaster';
import { CookieOptions, CookieService } from 'angular2-cookie/core';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    SharedComponentsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    LocalStorageModule.withConfig({
            prefix: 'my-app',
            storageType: 'localStorage'
        })
  ],
  providers: [
    CookieService,
    { provide: CookieOptions, useValue: {} },
    ToasterService,
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
