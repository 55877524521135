import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppVersion } from '../models/version.model';
import { catchError, first } from 'rxjs/operators';
import { of as ObservableOf } from 'rxjs';

@Injectable({
      providedIn: 'root'
})
export class VersionService {

    public runningVersion: string;
    public serverVersion: string;

    constructor(private http: HttpClient) {}

    async setCurrentAppVersion() {

        const appVersion = await this.getVersionFile();

        if (appVersion) {
            this.runningVersion = this.versionString(appVersion);
        }

    }

    versionString(version: AppVersion): string {
        return `${version.raw}${version.version}`;
    }

    async setServerAppVersion() {

        const appVersion = await this.getVersionFile();

        if (appVersion) {
            this.serverVersion = this.versionString(appVersion);
        }

    }

    getVersionFile(): Promise<AppVersion|null> {

        const timestamp = + new Date();

        return this.http.get<AppVersion>(`version.json?${timestamp}`)
            .pipe(
                first(),
                catchError(e => {
                    // console.error(e);
                    console.warn('Unable to get application version');
                    return ObservableOf(null);
                })
            ).toPromise();
    }

}
