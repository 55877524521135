
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppVersionRouteGaurd } from './services/app.version.route.gaurd';

const routes: Routes = [
    {
        path: '',
        loadChildren: './components/page1/page1.component.module#Page1ComponentModule',
        canActivate: [AppVersionRouteGaurd]
    },
    {
        path: 'login',
        canActivate: [AppVersionRouteGaurd],
        loadChildren: './components/login/login.component.module#LoginComponentModule'
    },
    {
        path: 'register',
        canActivate: [AppVersionRouteGaurd],
        loadChildren: './components/register/register.component.module#RegisterComponentModule'
    },
    {
        path: 'profile',
        canActivate: [AppVersionRouteGaurd],
        loadChildren: './components/profile/profile.component.module#ProfileComponentModule'
    },
    {
        path: 'update',
        canActivate: [AppVersionRouteGaurd],
        loadChildren: './components/update/update.component.module#UpdateComponentModule'
    },
    {
        path: 'needhelp',
        canActivate: [AppVersionRouteGaurd],
        loadChildren: './components/needhelp/needhelp.component.module#NeedHelpComponentModule'
    },
    {
        path: 'changepassword',
        canActivate: [AppVersionRouteGaurd],
        loadChildren: './components/changepassword/changepassword.component.module#ChangePasswordComponentModule'
    },
    {
        path: 'savedcard',
        canActivate: [AppVersionRouteGaurd],
        loadChildren: './components/savedcard/savedcard.component.module#SavedCardComponentModule'
    },
    {
        path: 'addnewcard',
        canActivate: [AppVersionRouteGaurd],
        loadChildren: './components/addnewcard/addnewcard.component.module#AddNewCardComponentModule'
    },
    {
        path: 'makepayment',
        canActivate: [AppVersionRouteGaurd],
        loadChildren: './components/makepayment/makepayment.component.module#MakePaymentComponentModule'
    },
    {
        path: 'building/:c_id',
        canActivate: [AppVersionRouteGaurd],
        loadChildren: './components/city/city.component.module#CityComponentModule'
    },
    {
        path: 'passwordreset/:id' ,
        canActivate: [AppVersionRouteGaurd],
        loadChildren: './components/passreset/passreset.component.module#PassResetComponentModule'
    },
    /*{
      path: '**',
      loadChildren: './components/error/error.component.module#ErrorComponentModule'
    }*/
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true, preloadingStrategy: PreloadAllModules})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
