import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { VersionService } from './version.service';

@Injectable({
    providedIn: 'root'
})
export class AppVersionRouteGaurd implements CanActivate {

    constructor(
        private versionService: VersionService
    ) {}

    canActivate() {

        // Load version.json file from server.
        this.versionService.setServerAppVersion();

        // Sleep for 150 milliseconds, while waiting for version file to be loaded
        // If it does not load in 150 milliseconds, results will be stored in VersionService...
        this.sleep(150);

        // Check if version has been set, if not allow route to be activated
        if (this.versionService.runningVersion && this.versionService.serverVersion) {
            if (this.versionService.runningVersion !== this.versionService.serverVersion) {
                window.location.reload(true);
                // return false; // While it reloads, deny access :)
            }
        }

        return true;
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

}
