import { Component, OnInit } from '@angular/core';
import { VersionService } from './services/version.service';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
    <toaster-container></toaster-container>
  `,
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  version: string;

  constructor(private versionService: VersionService) {}

  ngOnInit() {
    this.versionService.setCurrentAppVersion();
    this.version = this.versionService.runningVersion;
  }

}
