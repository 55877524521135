import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BusyModule } from 'angular2-busy';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToasterModule } from 'angular2-toaster';
import { Ng2PaginationModule } from 'ng2-pagination';
import { MomentModule } from 'ngx-moment';

const components = [
    CommonModule,
    BusyModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2PaginationModule,
    ToasterModule,
    MomentModule
];

@NgModule({
    declarations: [ ],
    imports: [
        ...components
    ],
    exports: [
        ...components
    ]
})
export class SharedComponentsModule {}
